@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Domine:wght@400;500;600;700&display=swap');
* {
  font-family: 'Roboto', serif;
}
body {
  overscroll-behavior-y: contain !important;
}
.App {

}
.pageLoading {
  width: 100%;
  text-align: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #343b3f;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: 14px;
  color: white;
  border-top: 1px solid black;
}
.bg-gydme {
  background-color: #333333;
}

/* MENU */
.navbar.navbar-dark .navbar-nav .nav-link {
  color:#ffffff;
}
.navbar.navbar-dark .nav-item.dropdown a {
  color:#ffffff;
}
.navbar.navbar-dark .nav-item.dropdown .dropdown-menu a {
  color:#000000;
}
.mainMenu .dropdown-item.active, .dropdown-item:active {
  background-color: #00dfe5;
}
.navbar-brand, .navbar-text a {
  color:black;
  cursor: pointer;
}
.nav-link:hover {
  color:black;
}
.navbar-primary .navbar-toggler-icon {
}

.navbar-light .navbar-nav .nav-link {
  color:#000000;
}

.languagePicker {
  padding-left:20px;
}

.App-link {
  color: #61dafb;
}
.btn-primary {
  color: #3a3a3a;
  background-color: #00dfe5;
  border-color: #22b5bd;
}
.bg-dark {
  background-color: #333333!important;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #ffffff;
}

.btn-primary.focus, .btn-primary:focus {
  background-color: #00dfe5;
  border-color: #22b5bd;
  box-shadow: none;
  color: #3a3a3a;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mainMenu__home-icon {
  vertical-align: text-top;
  line-height: 0px;
  padding-right: 0px;
  margin-right: 3px;
}

.mainMenu .nav-link {
  align-items:center;
  display:flex;
}

.mainMenu .dashboard_link {
  display:flex;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

/* HCP FOOTER */
.hcpFooterWrapper {
  margin-top: 20px;
  background-color: #333333;
  padding: 20px;
}

.hcpFooterWrapper p {
  margin-bottom: 0px;
  color: #FFFFFF;
}

.hcpFooterWrapper .footer-address p {
  font-size:14px;
}
@media (min-width: 576px) { 
  .hcpFooterWrapper .footer-address p {
    font-size: 1rem;
  } 
}

.hcpFooterWrapper a {
  color: #00dfe5;
}
.hcpPresentation .navbar-brand, .navbar-text a {
  color:black;
  cursor: default;
}
.hcpPresentation .hcpFooterWrapper {
  margin-top:auto;
}

/* Dashboard  */
.card-icons{
  display: flex;
  justify-content: flex-end;
}
.dashboard {
  margin-top: 2rem;
  margin-bottom: 2rem;
  min-height: 99vh;
}

.dashboard__page_title {
  font-size:2em;
  font-weight: 600;
}

.dashboard .card .card-footer {
  padding:0px;
}

.btn-launch-presentation {
  padding: 0.8rem;
  text-transform: uppercase;
  font-size: 1rem;
  border-radius: 0px;
  width: 100%;
  font-family: Barlow, sans-serif;
  font-weight: 600;
}


/* PATIENT LOGIN   */
.formContanierPatient {
  background-color: #00a999;
}

.formContanierPatient form {
  padding: 20px;
}

.formContanierPatient form button {
  width:100%;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  background: #292929 !important;
  min-height: 100vh;
  font-weight: 400;
}

.formContanierPatient.auth-inner {
  width: 90%;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 15px 30px 25px 15px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

/* Patient Login Form Error */
.patientFormError {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}


/* Patient Waiting For Access Screen */
.waiting-for-access {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.waiting-for-access p {
  text-align:center;
  margin-top:15px;
}

.waiting-for-access__rotate-instructions {
  display:none;
}
/* Utility */

.valign-txt-bottom {
  vertical-align: text-bottom;
}

/* .mobile-rotate-device {
  display:none;
} */
/* .hide{
  display: none !important;
} */
#editPresentationForm__input{
  display: none;
}

.editPresentationForm_icon-button-file {
  margin-bottom:0px;
}
.editPresentationForm__image-uploader{
  display: flex;
  align-items: baseline;
}

.editPresentationForm__page_title {
  font-size:2em;
  font-weight: 600;
}
.editPresentationForm {
  margin-top:40px;
}
.editPresentationScreen__formWrapper {
  min-height:87vh;
}
.editPresentationForm .form-row{
  margin-top: 25px;
}
.editPresentationForm .form-row label, .editPresentationForm .form-row .editPresentationForm__imgText {
  color:#000000;
  font-weight: 600;
  margin-bottom:0px;
}
.editPresentationForm_icon-button-file {
  padding-top:0px;
  padding-bottom:0px;
}
.editPresentationForm__success-message-edit {
  margin-top: 0px;
  font-weight: 600;
}
.editPresentationForm__success-message-edit img{
 width: 50%;
}
.error-message-edit{
  color:#dc3545;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation:portrait) {
  .waiting-for-access__rotate-instructions {
    display:block;
  }
  .mobile-rotate-device { 
    display: block;
    width: 350px;
    margin: auto;
    max-width: 350px;
  }
    
}

@media screen and (orientation:landscape) {
  .waiting-for-access__rotate-instructions {
    display:none;
  }
  .mobile-rotate-device { 
    display:none;
  }
}

/* Account Preferences */
.updateAccountForm, .deleteAccountForm {
  width: 100%;
  background: #fff;
  box-shadow: 0 14px 80px rgb(34 35 58 / 20%);
  padding: 15px;
  border-radius: 0;
  transition: all .3s;
  margin: 0 auto;
  padding-top: 25px;
}

@media (min-width: 576px) { 
  .updateAccountForm, .deleteAccountForm {
    width: 100%;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
    padding: 30px;
    border-radius: 0px;
    transition: all .3s;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
