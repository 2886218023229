.HcpLogin {
    min-height:100vh;
}

.ResetPasswordForm {
    background: #ffffff;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
    padding: 30px;
    border-radius: 15px;
    transition: all .3s;
    margin-top: 0px;
}

.ResetPasswordForm .form-label {
    font-weight:500;
}

.ResetPasswordForm .error-message-login {
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.ResetPasswordForm .success-message-password-reset {
    margin-top:20px;
    font-size:0.9em;
}
.ResetPasswordForm .error-message-reset-password {
    margin-top:20px;
    font-size:0.9em;
}