
.presentationUsersWrapper {
  position: relative;
	width: 100%;
	text-align: left;
}
.presentationUsername {
	background-color: #000000b0;
	color: white;
	padding: 6px;
	font-size: 10px;
	border-bottom: 1px solid #cccccc;
}
.userJoined {
	display: inline;
}