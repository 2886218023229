.presentationWrapper {
  width: 100%;
  background-color:#ffffff;
}

.patientPresentation .presentationWrapper{
  width: 100%;
  height: 100vh;
  display: flex;
}

.presentationWrapper.patient {
  width: 100%;
  /* height: 100vh;
  background-color: #2e2e2e;
  display: flex; */
}
.presentationWrapper.patient .carousel.slide {
  width: 100%;
  display: flex;
}

.hcpPresentation .presentationWrapper.hcp, .hcpPresentation .presentationWrapper.patient{
  background-color:#1A1A1A;
}
.hcpPresentation {
  background-color: #4e5256;
}
.hcpPresentation .presentationWrapper.hcp {
  /* max-width: 86%; */
  margin: auto;
  /* margin-top: 20px;
  margin-bottom: 20px; */
}

.hcpPresentation .slideInformation {
  display: block;
  overflow: scroll;
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  background-color: #1A1A1A;
  color: white;
  text-align: center;
}
.hcpPresentation .slideNumbersSpeedDial {
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #1a1a1a;
  color: white;
}
.hcpPresentation .slideNumbers {
  text-align: center;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 0px;
  margin-left: 0px;
  max-width: 1299px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.hcpPresentation .slideNumbers .btn-end_meeting {
  margin-right:0px;
}
.hcpPresentation .patient .carousel-inner {
  background-color: #1A1A1A;
}
.hcpPresentation .hcp .carousel-inner, .hcpPresentation .patient .carousel-inner {
  background-color: #1A1A1A;
  width: 95%;
  overflow: hidden;
  margin: auto;
}

.hcpPresentation .viewingPresentation {
  background-color:#1A1A1A;
}
.hcpPresentation .viewingPresentationWrapper {
  padding: 5px 15px;
}
.hcpPresentation .presentationUsersWrapper .list-group-item {
  background-color:#1A1A1A;
  color: white;
  border-radius:0px;
  border-bottom:none;
}
.hcpPresentation .viewingPresentation .list-group-item .badge {
  padding: 4px;
  margin-left: 5px;
}
.hcpPresentation .carousel-control-next, 
.hcpPresentation .carousel-control-prev {
  width: 2.5%;
}
/* HCP PRESENTATION  */
.hcpPresentation .requestedAccessToast {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  left: 50%;
  transform: translate(-25%, 0);
}

.hcpPresentation .requestedAccessToast .toast-body {
  background-color: #FFFFFF;
}

.hcpPresentation .qr-code-button-wrapper {
  margin-top: 0px;
}
.hcpPresentation .btn-qr-code, .hcpPresentation .btn-toggle-presentation-view {
  width: 100%;
  border-radius: 0px;
  padding: 1rem;
  text-transform:uppercase;
}

.hcpPresentation__invite_patient .modal-header {
  border: none;
  padding-bottom: 0px;
  text-align:center;
}
.hcpPresentation__invite_patient .modal-header .modal-title {
  width:100%;
  font-weight:700;
}
.hcpPresentation__invite_patient .modal-footer {
  justify-content:center;
}
.hcpPresentation__invite_patient .modal-dialog {
  max-width: 764px;
}
.hcpPresentation__invite_patient .tab-content {
  margin-top: 1rem;
}
.hcpPresentation__invite_patient .qrCodeWrapper {
  text-align:center;
}
.hcpPresentation__invite_patient .modal-body {
  padding:1rem 0px;
}

.hcpPresentation__pin_code {
  font-size: 1rem;
}


.carousel.slide {
  width: 100%;
}
.hcpPresentation .carousel.slide {
  min-height: calc(100vh - 370px);
  display:flex;
}

.hcpPresentation .hcp .carousel.slide, .hcpPresentation .patient .carousel.slide {
  max-width:1366px;
  margin: auto;
}

.carousel.slide img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
  max-width: 1366px;
  margin: auto;
  background-color:#ffffff;
}

.hcpPresentation .presentationWrapper.patient .carousel.slide img {
  max-height: none;
}

.presentationWrapper.patient .carousel.slide img {
  max-height: 100vh;
  width: auto!important;
  margin: auto;
  max-width: 100%;
}

.carousel-indicators {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: block;
  justify-content: center;
  padding-left: 0;
  margin-right: 0px;
  margin-left: 0px;
  list-style: none;
  margin-bottom: 0px;
  display: block;
  overflow-x: scroll;
  width: 100%;
}
.carousel-indicators {
  overflow: auto;
  overflow-y: hidden;
  -ms-overflow-style: scroll;
  scrollbar-width: none;
  position: relative !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.carousel-indicators::-webkit-scrollbar {
    display: none;
}

.carousel-indicators li {
  opacity: 1;
  border: 1px solid black;
  width: 130px;
  height: 63px;
  display:inline-block;
  text-indent:0px;
}
.carousel-indicators li span {
  background-color: #1a1a1a;
  width: 20px;
  display: block;
  height: 20px;
  font-size: 12px;
  color: white;
  z-index: 999999;
}
.carousel-indicators .active {
  opacity: 1;
  border: 3px solid #54dde4;
}