.activateRegistrationScreen {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .activateRegistrationScreen__container {
    height: 100%;
    min-height: 87vh;
  }
  
  .activateRegistrationScreen__intro {
    padding-top:60px;
    padding-bottom:60px;
  }
  
  .activateRegistrationScreen__intro h2 {
    padding-top: 20px;
  }
  
  .activateRegistrationScreen__intro h3 { 
    padding-top: 20px;
  }
  
  .activateRegistrationScreen__intro h4 { 
    font-size: 1.4rem;
    padding-top: 30px;
  }
  
  .activateRegistrationScreen__formWrapper{
    padding-top: 60px;
    padding-bottom:60px;
    background-color: #ffffff;
  }