.patient_login_screen {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  background: #3a3a3a !important;
  min-height: 100vh;
  font-weight: 400;
  align-content: center;
  height: 100%;
}

.patient_login_screen__gydme-logo {
  display: flex;
  justify-content: center;
  padding: 1em;
}
.patient_login_screen__gydme-logo img {
  width: 75%;
  max-width: 200px;
}
.patient_login_screen__formContainerPatient.auth-inner{
  width: 90%;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
  padding: 30px;
  border-radius: 15px;
  transition: all .3s;
  margin-top: 0px;
  margin-bottom: 0px;
}

.patient_login_screen__patientFormError {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
}
.patient_login_screen .error {
  animation: shake 0.2s ease-in-out 0s 2;
}

.patient_login_screen .form-label {
  font-weight:500;
}

.patient_login_footer {
  flex-direction: column;
} 

@media (min-width: 576px) { 
  .patient_login_footer {
    flex-direction: row;
  } 
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}