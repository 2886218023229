.activateRegistrationForm {
    width: 100%;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
    padding: 15px;
    border-radius: 15px;
    transition: all .3s;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media (min-width: 576px) { 
    .activateRegistrationForm {
      width: 90%;
      margin: auto;
      background: #ffffff;
      box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
      padding: 30px;
      border-radius: 15px;
      transition: all .3s;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  
  .activateRegistrationForm .form-control {
    padding: 1.2rem;
  }
  .activateRegistrationForm label {
    font-weight:500;
  }
  
  .activateRegistrationForm .submit {
    width:100%;
  }
  
  .activateRegistrationForm h2 {
    margin-bottom:1.5rem;
  }
  
  .activateRegistrationForm .login_link {
    margin-left:5px;
  }
  
  .activateRegistrationForm .error-message-register {
    color: red;
    margin: 5px 0px;
    border: 1px solid red;
    padding: 5px;
    font-size: 0.8em;
  }

  .activateRegistrationForm .activateRegistrationForm__button {
    padding-top:0.6em;
    padding-bottom:0.6em;
  }