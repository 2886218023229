.admin_page {
  margin-top: 4rem;
  margin-bottom: 4rem;
  min-height: 85vh;
}

.admin_page__page_title {
  font-size: 2em;
  font-weight: 600;
}


.admin_page__list_users {
  margin-top:60px;
}

.admin_page__list_users .MuiDataGrid-columnHeader, .admin_page__list_users .MuiDataGrid-columnHeadersInner {
  background-color:black;
  color:white;
}

.admin_page__list_users .MuiDataGrid-iconButtonContainer svg{
  color:white
}

.admin_page .invite_user_type__button {
  width:49%;
  padding: 10px;
}
.admin_page .invite_user_type__button:nth-child(1) {
  margin-right:2%;
}

.admin_page .invite_user_type__button.active {
  color: #ffffff;
  background-color: #118F96;
  padding: 10px;
}

.admin_page .admin_page__invite_options {
  margin-top:30px;
}