.HcpLogin {
    min-height:100vh;
}

.LoginForm {
    background: #ffffff;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
    padding: 30px;
    border-radius: 15px;
    transition: all .3s;
    margin-top: 0px;
}

.LoginForm .form-label {
    font-weight:500;
}

.LoginForm .error-message-login {
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}


.HcpLogin .hcp_login_screen__gydme-logo {
    display: flex;
    justify-content: center;
    padding: 1em;
    margin-top: 5%;
    margin-bottom: 1%;
}

.HcpLogin .hcp_login_screen__gydme-logo img {
    width: 75%;
    max-width: 200px;
}