.registrationScreen {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

.registrationScreen__container {
  height: 100%;
}

.registrationScreen__intro {
  padding-top:60px;
  padding-bottom:60px;
}

.registrationScreen__intro h2 {
  padding-top: 20px;
}

.registrationScreen__intro h3 { 
  padding-top: 20px;
}

.registrationScreen__intro h4 { 
  font-size: 1.4rem;
  padding-top: 30px;
}

.registrationScreen__formWrapper{
  padding-top: 19px;
  padding-bottom:60px;
  background-color: #ffffff;
}